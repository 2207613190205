import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { AccentLink } from '@playbooks/interface/links';
import { useAccount } from 'contexts';

const NotificationNavLink = () => {
	const [open, setOpen] = useState(false);
	const account = useAccount();
	const router = useRouter();

	// Hooks
	useEffect(() => {
		setOpen(false);
	}, [router.pathname]);

	// Methods
	const onToggle = () => setOpen(!open);

	// Render
	return (
		<Span position='relative' width='w-full'>
			{account.account.totalNotifications > 0 && (
				<Span
					align='text-center'
					display='block'
					inset='-bottom-3'
					position='absolute'
					spacing='mx-auto'
					width='w-full'
					zIndex='z-10'>
					<Badge size='xxs' type='active' spacing='p-0.5' />
				</Span>
			)}
			<AccentLink size='icon' icon='bell' href='/notifications' />
		</Span>
	);
};

export { NotificationNavLink };
