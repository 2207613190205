import { Fragment } from 'react';

import { Div } from '@playbooks/interface/html';
import { BorderLink } from '@playbooks/interface/links';
import { FollowBtn } from 'components/follow/follow-btn';
import { TeamSettingsDrop } from 'components/team/team-settings-drop';
import { useAccount } from 'contexts';

export const TeamCardActions = ({ type, team, taskRunning, rootLink, onClick }) => {
	const account = useAccount();
	const isOwner = team.owner?.uuid === account.account.uuid && account.type === 'User';
	const props = { team, isOwner, taskRunning, rootLink, onClick };

	// Render
	switch (type) {
		case 'action':
			return <TeamActionCardActions {...props} />;

		case 'grid':
			return <TeamGridCardActions {...props} />;

		case 'list':
			return <TeamListCardActions {...props} />;
	}
};

export const TeamActionCardActions = ({ team, isOwner, taskRunning, onClick }) => (
	<Div display='flex-end' space='space-x-4'>
		<TeamSettingsDrop team={team} taskRunning={taskRunning} onClick={onClick} />
	</Div>
);

export const TeamGridCardActions = ({ team, isOwner, rootLink }) => (
	<Div display='flex-between' space='space-x-4' width='w-full'>
		{isOwner ? (
			<BorderLink prevIcon='gear' href={`${rootLink}/settings`} className='w-full'>
				Settings
			</BorderLink>
		) : (
			<FollowBtn type='profile' model={team} rootApi={`/teams/${team?.uuid}`} />
		)}
	</Div>
);

export const TeamListCardActions = ({ team, isOwner, rootLink }) => (
	<Fragment>
		{isOwner ? (
			<BorderLink size='icon' icon='gear' href={`${rootLink}/settings`} />
		) : (
			<FollowBtn type='card' model={team} rootApi={`/teams/${team?.uuid}`} />
		)}
	</Fragment>
);
