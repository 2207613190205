import { Fragment, useState } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Drop, DropBtn, DropItem, DropList, DropMenu } from '@playbooks/interface/drops';
import { useAccount } from 'contexts';

const TeamSettingsDrop = ({ team, taskRunning, onClick }) => {
	const [open, setOpen] = useState(false);
	const account = useAccount();

	// Computed
	const isOwner = team.owner?.uuid === account.account.uuid && account.type === 'User';

	// Methods
	const onToggle = () => setOpen(!open);

	const onClose = () => setOpen(false);

	// Render
	return (
		<Drop open={open} setOpen={onClose}>
			<BorderBtn size='icon' icon='ellipsis' onClick={onToggle} />

			<DropMenu open={open} className='right-0'>
				<DropList border='border-b'>
					<DropItem>
						<DropBtn prevIcon='eye' onClick={() => onClick('view', team)}>
							View
						</DropBtn>
					</DropItem>
					{isOwner && (
						<Fragment>
							<DropItem>
								<DropBtn prevIcon='cog' onClick={() => onClick('edit', team)}>
									Manage
								</DropBtn>
							</DropItem>
							<DropItem>
								<DropBtn
									prevIcon='trash'
									color='red-500'
									taskRunning={taskRunning.id === team.id && taskRunning.name === 'delete'}
									onClick={() => onClick('delete', team)}>
									Delete
								</DropBtn>
							</DropItem>
						</Fragment>
					)}
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { TeamSettingsDrop };
