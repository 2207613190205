import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { toCurrency } from 'utils';

const RepoBadges = ({ repo, ...props }) => {
	return (
		<Fragment>
			{repo.meta?.avgRating > 0 && (
				<Badge {...props}>
					<FadIcon icon='star' />
					<Span>{repo.meta?.avgRating}</Span>
				</Badge>
			)}
			{repo.price > 0 && (
				<Badge {...props}>
					<Span>{repo.price > 0 ? toCurrency(repo.price) : 'Free'}</Span>
				</Badge>
			)}
			{repo.visibility === 'private' && (
				<Badge {...props}>
					<FadIcon icon='lock' />
				</Badge>
			)}
		</Fragment>
	);
};

export { RepoBadges };
